body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.img-fluid {
  height: -webkit-fill-available !important;
}

.bg-primary {
  background-color: #000000 !important;
}
.btn-primary {
  background-color: #000000 !important;
  border-color: #000000 !important;
}
.carousel-control-next-icon {
  background-color: #000000 !important;
  border-color: #000000 !important;
}
.carousel-control-prev-icon {
  background-color: #000000 !important;
  border-color: #000000 !important;
}
.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
  color: #000000 !important;
}
.text-primary {
  color: #000000 !important;
}

@font-face {
  font-family: "posterma";
  src: url("../public/fonts/Posterama\ 2001\ W04\ Black.woff2.ttf");
}

@media screen and (max-width: 900px) {
  .img-fluid {
    height: auto !important;
  }
}
